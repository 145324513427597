import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import CookieBot from 'react-cookiebot';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

const domainGroupId = "a95a6c1a-56b0-4005-a451-1c120ccea720";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
      <CookieBot domainGroupId={domainGroupId} />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// reportWebVitals();
