import React from 'react';

class LazyLoader extends React.Component {
    state = {
        show: false
    };

    componentDidMount() {
        const { delay } = this.props;
        if(delay && delay > 0)
        {
            const timeout = setTimeout(() => {
                this.setState({ show: true });
            }, delay);
            clearTimeout(timeout);
        }
        else
        {
            this.setState({ show: true });
        }
    }

    render() {
        const { show } = this.state;
        return(show ? <div className="d-flex justify-content-center py-5 " style={{height: "1000px !important"}}> <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div> </div> : null);
    }
}

export default LazyLoader;