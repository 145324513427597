import React, { Suspense, lazy } from 'react';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import LazyLoader from './components/LazyLoader';
import { Webchat } from '@ciptex/race-sdk';

import darkLogo from './assets/img/dark-logo.svg';
import lightLogo from './assets/img/light-logo.svg';
import phoneReceiver from './assets/img/icons/004-phone-receiver.svg';
import poweredBy from './assets/img/ciptex-logo.svg';
import linkedIn from './assets/img/vector/linked.svg';
import externalSVG from './assets/img/external-link-alt-solid.svg';

const HomePage = lazy(() => import('./components/HomePage'));
const AgentAssistedTelephony = lazy(() => import('./components/AgentAssistedTelephony'));
const DigitalPayments = lazy(() => import('./components/DigitalPayments'));
const SelfServiceIVR = lazy(() => import('./components/SelfServiceIVR'));
const PauseResume = lazy(() => import('./components/PauseResume'));
const ContactUs = lazy(() => import('./components/ContactUs'));
const EventsPage = lazy(() => import('./components/EventsPage'));
const InfoCentre = lazy(() => import('./components/InfoCentre'));
const CookiePolicy = lazy(() => import('./components/CookiePolicy'));

class App extends React.Component {

  componentDidMount() {
    const webchat = new Webchat("AC2cd78138aa196a6560db47e44474975b", "FOa513622622f256d5805aa545c5652194");
    webchat.init().then(() => {
      console.log("Init Successful");
  });
  }

  historyChange = ({ pageName }) => {
    if (pageName) {
      window.analytics.page(pageName);
    }
  }

  getYear() {
    return new Date().getFullYear();
  }

  Click = (e) => {
    window.analytics.trackLink(e.target, 'Click To Call Link Clicked', {});
  };

  render() {
    return (
      <div className="App">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container">
            <div className="navbar-left mr-4">
              <button className="navbar-toggler" type="button">☰</button>
              <Link className="navbar-brand" to="/">
                <img className="logo-dark" src={darkLogo} alt="logo" />
                <img className="logo-light" src={lightLogo} alt="logo" />
              </Link>
            </div>
            <section className="navbar-mobile">
              <ul className="nav nav-navbar">
                <li className="nav-item">
                  <a className="nav-link " href="/">Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#Solutions">Solutions <span className="arrow"></span></a>
                  <nav className="nav">
                    <a className="nav-link" href="/agent-assisted">Agent Assisted Telephony</a>
                    <a className="nav-link" href="/digital-payments">Secure Digital Payments</a>
                    <a className="nav-link" href="/self-service">Self-service IVR Payments</a>
                    <a className="nav-link" href="/pause-resume">Pause and Resume</a>
                  </nav>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact-us">Contact us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/info-centre">Info Centre</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/events">Events</a>
                </li>
              </ul>
              <div style={{ marginLeft: 'auto' }}>
                <a className="no-btn btn btn-sm btn-light ml-lg-5 mr-2 margin-correction" href="tel:+448081961676"><img
                  className="web-icons" style={{ marginBottom: '3px', marginRight: '5px' }}
                  src={phoneReceiver} width="10" alt="" /> 0808 196 1676</a>
                <a className="btn btn-sm btn-success" href="contact-us">Email us</a>
              </div>
            </section>

          </div>
        </nav>
        <Suspense fallback={<LazyLoader delay={0} />}>
          <Switch>
            <Route exact path="/">
              <HomePage historyChange={this.historyChange} />
            </Route>
            <Route exact path="/agent-assisted">
              <AgentAssistedTelephony historyChange={this.historyChange} />
            </Route>
            <Route exact path="/digital-payments">
              <DigitalPayments historyChange={this.historyChange} />
            </Route>
            <Route exact path="/self-service">
              <SelfServiceIVR historyChange={this.historyChange} />
            </Route>
            <Route exact path="/pause-resume">
              <PauseResume historyChange={this.historyChange} />
            </Route>
            <Route exact path="/contact-us">
              <ContactUs historyChange={this.historyChange} />
            </Route>
            <Route exact path="/events">
              <EventsPage historyChange={this.historyChange} />
            </Route>
            <Route exact path="/info-centre">
              <InfoCentre historyChange={this.historyChange} />
            </Route>
            <Route exact path="/cookie-declaration">
              <CookiePolicy historyChange={this.historyChange} />
            </Route>
            <Route path="*">
              <HomePage historyChange={this.historyChange} />
            </Route>
          </Switch>
        </Suspense>
        <footer className="footer py-7">
          <div className="container">
            <div className="row gap-y">
              <div className="col-xl-3 order-md-last">
                <h6 className="mb-4"><strong>About</strong></h6>
                <p>From Ciptex - The people who know contact centres</p>
                <p>Our official <a href="https://www.linkedin.com/company/onepaymentcloud/" target="_blank" rel="noreferrer"><img
                  src={linkedIn} width="20" alt="One Payment Cloud Linkedin" /></a></p>
                <a className="btn btn-sm btn-success mr-2 mb-2" href="contact-us">Email us</a>
                <a className="btn btn-sm btn-secondary mb-2" href="tel:+448081961676"><img className="web-icons"
                  style={{ marginBottom: '3px', marginRight: '5px' }} src={phoneReceiver} width="10"
                  alt="" /> 0808 196 1676</a>
              </div>
              <div className="col-6 col-md-4 col-xl-3">
                <h6 className="mb-4"><strong>Solutions</strong></h6>
                <div className="nav flex-column">
                  <a className="nav-link" href="/agent-assisted">Agent Assisted Telephony</a>
                  <a className="nav-link" href="/digital-payments">Secure Digital Payments</a>
                  <a className="nav-link" href="/self-service">Self-service IVR Payments</a>
                  <a className="nav-link" href="/pause-resume">Pause and Resume</a>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-3">
                <h6 className="mb-4"><strong>Company</strong></h6>
                <div className="nav flex-column">
                  {/* <a className="nav-link" href="/gdpr-disclaimer">GDPR Disclaimer</a> */}
                  {/* <a className="nav-link" href="#">Certifications</a> */}
                  <a className="nav-link" href="https://www.ciptex.com/legal/privacy" target="_blank" rel="noreferrer">Privacy Policy <img src={externalSVG} alt="External" width="12" className="ml-1"></img> </a>
                  <a className="nav-link" href="https://www.ciptex.com/legal/sla" target="_blank" rel="noreferrer">SLA <img src={externalSVG} alt="External" width="12" className="ml-1"></img> </a>
                  <a className="nav-link" href="https://www.ciptex.com/legal/data-protection" target="_blank" rel="noreferrer">Data Processing Agreement <img src={externalSVG} alt="External" width="12" className="ml-1"></img> </a>
                  <a className="nav-link" href="/cookie-declaration">Cookie Policy</a>
                  <a className="nav-link" href="/contact-us">Contact us</a>
                </div>
              </div>

              <div className="col-xl-3 order-md-first">
                <img className="logo-dark" src={darkLogo} style={{ paddingBottom: '10px' }} width="100" alt="logo" />
                <br />
                <small className="opacity-70">© {this.getYear()} Ciptex Ltd. All rights reserved.</small> <br />
                <small className="opacity-70">ICO Registration number: ZA137273</small> <br /><small className="opacity-70">Company Registration Number: 05671321</small><br /><br />
                <p className="fw-400">Powered by <a href="https://www.ciptex.com/" target="_blank" rel="noreferrer"><img
                  src={poweredBy} width="70" alt="Powered by Ciptex" /></a> </p>
              </div>

            </div>
          </div>
        </footer>


      </div>
    );
  }
}

export default withRouter(App);
